<template>
    <div>
        <sidebar-general
            ref="sidebarEditarPaciente"
            title="Editar paciente"
            aceptar="Editar"
            @aceptar="editarPaciente"
            :cargando="loading"
        >
            <ValidationObserver ref="form">
                <b-container class="pt-1">
                    <div>
                        <label for="nombre">Nombre</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="nombre"
                            rules="required|max:25"
                        >
                            <b-form-input
                                v-model="paciente.nombre"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Nombre"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                    </div>
                    <div class="mt-1">
                        <label for="apellido">Apellido</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="apellido"
                            rules="required|max:25"
                        >
                            <b-form-input
                                v-model="paciente.apellido"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Apellido"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                    </div>
                    <div class="mt-1">
                        <label>Email</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="email"
                            rules="required|email|max:50"
                        >
                            <b-form-input
                                v-model="paciente.email"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Email"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                    </div>
                    <div class="mt-1">
                        <label>Teléfono</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="email"
                            rules="required|numeric|max:10"
                        >
                            <b-form-input
                                type="number"
                                v-model="paciente.telefono"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Teléfono"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                    </div>
                    <div class="mt-1">
                        <label for="identificacion">Identificación</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="identificación"
                            rules="required|numeric|min:5|max:10"
                        >
                            <b-form-input
                                type="number"
                                v-model="paciente.identificacion"
                                placeholder="123456789"
                                :state="errors.length > 0 ? false : null"
                            >
                            </b-form-input>
                            <small class="text-danger"> {{ errors[0] }}</small>
                        </ValidationProvider>
                    </div>
                    <div class="mt-1">
                        <label for="tipo identificacion"
                            >Tipo de identificación</label
                        >
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="tipo identificación"
                            rules="required|between:1,3"
                        >
                            <v-select
                                v-model="paciente.tipo_identificacion"
                                :filterable="false"
                                placeholder="Seleccionar tipo"
                                :options="tiposDoc"
                                label="nombre"
                                :reduce="(doc) => doc.id"
                            >
                            </v-select>
                            <small class="text-danger"> {{ errors[0] }}</small>
                        </ValidationProvider>
                    </div>
                    <div class="mt-1">
                        <label for="password">Contraseña</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="contraseña"
                            rules="max:20"
                        >
                            <b-form-input
                                v-model="paciente.password"
                                :state="errors.length > 0 ? false : null"
                                placeholder="******"
                            ></b-form-input>
                            <small class="text-danger"> {{ errors[0] }} </small>
                        </ValidationProvider>
                    </div>
                    <div class="mt-1">
                        <label for="password">Confirmar contraseña</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="confirmar contraseña"
                            rules="confirmed:contraseña|max:20"
                        >
                            <b-form-input
                                v-model="paciente.password_confirmation"
                                :state="errors.length > 0 ? false : null"
                                placeholder="******"
                            ></b-form-input>
                            <small class="text-danger"> {{ errors[0] }} </small>
                        </ValidationProvider>
                    </div>
                    <div class="mt-1">
                        <label for="nacimiento">Fecha de nacimiento</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="fecha nacimiento"
                            rules="required"
                        >
                            <b-form-datepicker
                                :readonly="true"
                                label-reset-button="Borrar"
                                v-model="paciente.fecha_nacimiento"
                                placeholder="Fecha"
                                :state="errors.length > 0 ? false : null"
                                reset-button
                                :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }"
                            ></b-form-datepicker>
                            <small class="text-danger"> {{ errors[0] }}</small>
                        </ValidationProvider>
                    </div>

                    <div class="mt-1">
                        <label for="genero">Genero</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="genero"
                            rules="required"
                        >
                            <v-select
                                v-model="paciente.genero"
                                :options="generos"
                                :reduce="(genero) => genero.id"
                                label="genero"
                                placeholder="Selecciona el genero"
                            ></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                    </div>
                    <b-alert
                        v-model="showDismissibleAlert"
                        variant="warning"
                        class="my-1 p-1"
                        dismissible
                        fade
                    >
                        <feather-icon icon="AlertCircleIcon"></feather-icon>
                        ¡Cuidado! Este usuario actualmente está asociado a una
                        entidad.
                    </b-alert>
                    <!-- <div class="mt-1 pb-1">
                        <label for="vincular">Vincular a una entidad</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="entidad"
                            rules="required"
                        >
                            <v-select
                                :options="entidadesUser"
                                placeholder="Buscar entidad"
                                :reduce="(entidad) => entidad.id"
                                label="nombre"
                                v-model="paciente.id_entidad"
                            >
                                <template #no-options>
                                    No se encontraron entidades
                                </template>
                            </v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                    </div> -->
                    <!-- <div class="mt-1 pb-1 border-bottom">
                        <div
                            :class="`d-flex justify-content-between ${
                                usaDispositivos ? 'mb-1 zindex-3' : ''
                            } `"
                        >
                            <span>¿Usa dispositivos?</span>
                            <b-form-checkbox
                                v-model="usaDispositivos"
                                switch
                            ></b-form-checkbox>
                        </div>
                        <v-select
                            v-if="usaDispositivos"
                            :options="[
                                'Blood pressure',
                                'Body composition',
                                'Weight Scale',
                            ]"
                            multiple
                            placeholder="Selecciona un dispositivo"
                            class="animate__animated animate__jello"
                        ></v-select>
                    </div> -->
                </b-container>
            </ValidationObserver>
        </sidebar-general>
    </div>
</template>
<script>
import Pacientes from "@/services/pacientes";
import Users from "@/services/users";
import { ref, reactive, onMounted } from "@vue/composition-api";
export default {
    setup(props, context) {
        const loading = ref(false);

        const usaDispositivos = ref(false);

        const entidadesUser = ref([]);

        const timeout = ref(null);

        const showDismissibleAlert = ref(false);

        const paciente = reactive({
            id: null,
            telefono: null,
            nombre: "",
            apellido: "",
            email: "",
            password: undefined,
            password_confirmation: undefined,
            fecha_nacimiento: "",
            genero: undefined,
            tipo_identificacion: null,
            identificacion: null,
        });

        const generos = ref([
            {
                genero: "Indefinido",
                id: 0,
            },
            {
                genero: "Masculino",
                id: 1,
            },
            {
                genero: "Femenino",
                id: 2,
            },
        ]);

        const tiposDoc = ref([
            {
                nombre: "cc",
                id: 1,
            },
            {
                nombre: "ti",
                id: 2,
            },
            {
                nombre: "ce",
                id: 3,
            },
        ]);

        function toggle(pacienteData) {
            paciente.id = pacienteData.id;
            paciente.telefono = pacienteData.telefono;
            paciente.nombre = pacienteData.nombre;
            paciente.apellido = pacienteData.apellido;
            paciente.email = pacienteData.email;
            paciente.identificacion = pacienteData.identificacion;
            paciente.tipo_identificacion = pacienteData.tipo_identificacion;
            paciente.fecha_nacimiento = pacienteData.fecha_nacimiento;
            paciente.genero = pacienteData.genero;
            showDismissibleAlert.value = pacienteData.id_entidad ? true : false;
            paciente.password = undefined;
            paciente.password_confirmation = undefined;
            context.refs.sidebarEditarPaciente.toggle();
            fetchOptions();
        }

        async function fetchOptions() {
            try {
                const { data } = await Users.getEntidadesAdmin();

                entidadesUser.value = data.data;
            } catch (error) {
                context.root.catch(error);
            }
        }

        async function editarPaciente() {
            try {
                let validate = await context.refs.form.validate();

                if (!validate)
                    return context.root.notificacion(
                        "Por favor completa los campos",
                        null,
                        "warning"
                    );

                loading.value = true;

                const { data } = await Pacientes.editarPaciente(paciente);

                context.root.notificacion(data.message, null, "success");
                context.emit("update");
                context.refs.sidebarEditarPaciente.toggle();
            } catch (error) {
                context.root.catch(error);
            } finally {
                loading.value = false;
            }
        }

        return {
            toggle,
            paciente,
            fetchOptions,
            entidadesUser,
            loading,
            generos,
            tiposDoc,
            editarPaciente,
            showDismissibleAlert,
        };
    },
};
</script>
